.formContainer {
  height: auto;
  width: 70%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin: 0 auto;
}

.inputField {
  font-size: 24px;
  width: 100%;
  height: 70px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid #020202;
}

.inputCombobox {
  width: '100%';
  max-width: '630px';
  height: '70px';
}

.error {
  border-color: red;
}

.radioContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.radioBox {
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 40px;
  align-items: center;
}

.radio {
  height: 20px;
  width: 20px;
}

.errorMessage {
  color: rgb(188, 40, 40);
  font-size: 16px;
}

.inputContainer {
  position: relative;
  width: 100%;
  max-width: 630px;
  align-self: baseline;
}

.iconContainer {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 24px;
  height: 24px;
}
