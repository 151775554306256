@use '../../styles/vars' as v;

.container {
  height: 100vh;
  display: flex;
}

.leftColumn {
  flex: 1;
  background-color: v.$light_blue;
}

.rightColumn {
  flex: 2;
  overflow-y: scroll;
}
