@use "./vars" as v;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  background: v.$primary_blue;
  color: white;
  padding: 15px 32px;
  height: 63.5px;
  border-radius: calc(63.5px / 2);
  font-size: 16px;
  font-weight: 600;
  box-shadow: none;
  border-style: none;
}

button:disabled {
  background: #BDBDBD;
  color: white;
}
