nav {
  ul {
    display: flex;
    justify-content: flex-start;
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      a {
        display: block;
        padding: 10px 20px;
        text-decoration: none;
        color: #333;
        transition: color 0.3s ease;

        &:hover {
          color: #007bff;
        }
      }
    }
  }
}
