#fullScreenIframe {
  height: 100%;
  width: 100%;
  border: none; /* Remove the default border */
  display: block; /* Remove line breaks/white space */
}

#root,
#root>div,
#root>div>div,
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden; /* Prevent scrollbars */
}
