.resultContainer {
  width: 70%;
  // flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}

.textContainer {
  // flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.registrationCode {
  font-size: 40px;
  width: 100%;
}

h1 {
  font-size: 20px;
  font-weight: normal;
  width: 100%;
}
